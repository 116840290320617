



































import {Component, Prop, Watch, Provide, Vue} from 'vue-property-decorator'
import {BoostedDapp} from '@/model/resource/BoostedDapp'
import {InputBoostedDappSchema} from '@/schema/resource/BoostedDapp/InputBoostedDappSchema'

@Component
export default class PersistBoostedDappView extends Vue {
  @Prop() id?: string

  @Provide('validator') validator = this.$validator

  schema = new InputBoostedDappSchema()
  boostedDapp = new BoostedDapp()

  async created() {
    this.schema.collectionDapp.hasContract = true

    this.populateResource()
    await this.populate()
  }

  populateResource() {
    this.schema.collectionBoosted.queryAsPage()
    this.schema.collectionDapp.queryAsPage()
  }

  async populate() {
    const id = Number(this.id) || null

    if (id) {
      await this.boostedDapp.getBoostedDapp(id)
    }

    this.$await.done('getBoostedDapp')
  }

  async persist() {
    const isValid = await this.validator.validateAll()

    if (!isValid) {
      this.$toast.abort('system.error.validation')
    }

    if (this.$route.params.id) {
      await this.boostedDapp.persistEditingBoostedDapp()
    } else {
      await this.boostedDapp.persistBoostedDapp()
    }

    this.$toast.success('system.success.persist')
    await this.$nav.push('/boosted-dapp/list')
  }
}
